var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("c-table", {
    ref: "table",
    attrs: {
      title: "위험성평가 시나리오 비교 목록",
      columns: _vm.gridColumns,
      data: _vm.grid.data,
      merge: _vm.gridMerge,
    },
    on: { rowClick: _vm.rowClick },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }